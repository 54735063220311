import React, { useContext, useRef, useState, useEffect } from "react";
import ConversationCard from "../ConversationCard";
import { Link, Route } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import ConversationDetails from "./ConversationDetailsOpen";
import "./OpenConversation.css";
import "./../Conversations.css";
import { ConversationContext } from "../../../store/context/ConversationContext";
import InfiniteScroll from "react-infinite-scroll-component";

import ClearButton from "../../Reusable/ClearButton/ClearButton";
import Select from "react-select";
import {AppContext} from "../../../store/context/AppContext";

const OpenConversations = () => {
  const { state, actions } = useContext(ConversationContext);
  const { state: AppState } = useContext(AppContext);
  const [searchedValue, setSearchedValue] = useState("");
  const [filter, setFilter] = useState({branch: null, language_id: ""});
  const [multiClose, setMultiClose] = useState(false);
  const [conversationsToClose, setConversationsToClose] = useState([]);
  const  [hasMore, setHasMore] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  let typingTimer;
  let doneTypingInterval = 1500;

  const otherConversationsRef = useRef(null);

  useEffect(() => {
    if (
      otherConversationsRef.current &&
      !state.uploadingMoreOpenConversations
    ) {
      state.uploadingMoreOpenConversations = true;
    }

    return () => {
      state.uploadingMoreOpenConversations = false;
  }
  }, [state]);

  function tappingKeys() {
    let text = document.getElementById("search_input_open").value;
    if (text !== searchedValue) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(searchConversation, doneTypingInterval);
    }
  }

  function stopTapping() {
    clearTimeout(typingTimer);
  }

  function searchConversation() {
    if (document.getElementById("search_input_open") != null) {
      let text = document.getElementById("search_input_open").value;
      let languages = filter.language_id;
      let branches = filter.branch;

      setSearchedValue(text);
      actions.searchConversation(text, true, branches, languages);
    }
  }

  function updateUnreadMessage(conversationId) {
    let item = document.getElementById("unread_" + conversationId);
    if (item != null) {
      item.style.display = "none";
    }
    actions.fetchCurrentConversation(conversationId);
  }

  function editMultipleConversations() {
    setMultiClose(true);
  }

  function stopEditMultipleConversations() {
    setMultiClose(false);
  }

  function addConversationToMultiClose(conversationId) {
    const indexId = conversationsToClose.indexOf(conversationId);
    if (indexId > -1) {
      let newArray = conversationsToClose.splice(indexId, 1);
      setConversationsToClose(newArray);
    } else {
      setConversationsToClose([...conversationsToClose, conversationId]);
    }
  }

  const changeFilter = (filter, data, name) => {
    let values = [];

    if (data != null)
    {
      for (let i = 0; i < data.length; i++)
      {
        values.push(data[i].id)
      }
    }

    setFilter({
      ...filter,
      [name]: values,
    })
  };

  function closeMultipleConversations() {
    actions.closeMultipleConversations(conversationsToClose);
    setMultiClose(false)
    setConversationsToClose([]);
  }

   function fetchMoreData() {
      state.currentPageOpen++;

       actions.fetchConversations(
          state.currentPageOpen,
          otherConversationsRef.current,
          "/open"
      );

     if ( state.currentPageOpen > state.conversations.pages ) {
       console.log("last page")
       setHasMore(hasMore => false);
     }
  }

  function dataLength(){
    return state.conversations.per_page * state.currentPageOpen;
  }

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  return (
    <Container fluid>
      {typeof state.conversations.filtered.open != "undefined" ? (
        <Row>
          <Col sm="4" className="mr-3">
            <div className="conversation__search">
              <textarea
                id="search_input_open"
                className="conversation-search"
                name="conversation"
                placeholder="search"
                onKeyUp={tappingKeys}
                onKeyDown={stopTapping}
              />

              {!multiClose ? (
                <Button
                  className="conversation__multi-close-on"
                  onClick={() => editMultipleConversations()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    height="100%"
                    width="100%"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path fill="none" d="M0 0h48v48H0z"></path>
                    <g>
                      <circle
                        fill="#838383"
                        className="st1"
                        cx="10"
                        cy="24"
                        r="2"
                      ></circle>
                      <circle
                        fill="#838383"
                        className="st1"
                        cx="24"
                        cy="24"
                        r="2"
                      ></circle>
                      <circle
                        fill="#838383"
                        className="st1"
                        cx="17"
                        cy="24"
                        r="2"
                      ></circle>
                    </g>
                  </svg>
                </Button>
              ) : (
                <>
                   <Button
                      className="conversation__multi-close-handle-btn"
                      onClick={() => closeMultipleConversations()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        height="100%"
                        width="100%"
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <path fill="none" d="M0 0h48v48H0z"></path>
                        <polygon
                          fill="#838383"
                          points="21.796,23.079 16.508,17.792 13.68,20.62 21.796,28.736 34.816,15.716 31.988,12.888"
                        ></polygon>
                        <path
                          fill="#838383"
                          d="M38,4H10c-4.411,0-8,3.589-8,8v17c0,4.411,3.589,8,8,8h1v10.27L23.324,37H38c4.411,0,8-3.589,8-8V12 C46,7.589,42.411,4,38,4z M42,29c0,2.206-1.794,4-4,4H21.876L15,38.73V33h-5c-2.206,0-4-1.794-4-4V12c0-2.206,1.794-4,4-4h28 c2.206,0,4,1.794,4,4V29z"
                        ></path>
                      </svg>
                    </Button>
                  <ClearButton onClick={() => stopEditMultipleConversations} />
                </>
              )}
            </div>
            <div className="conversation__select">
              <Row>
                <Col>
                  <Select
                      isMulti
                      id="language_id"
                      name="language_id"
                      className="conversation-dropdown__select"
                      options={AppState.inputs.languages}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      placeholder="by language"
                      onChange={(data) => changeFilter(filter, data, 'language_id')}
                  />
                </Col>
                <Col>
                  <button
                      className="search-input-field__btn search-btn-branch"
                      onClick={searchConversation}
                  ></button>
                </Col>
                <Col>
                  <Select
                      isMulti
                      id="branch"
                      name="branch"
                      className="conversation-dropdown__select"
                      options={AppState.inputs.companies}
                      getOptionLabel={(option) => `${option.branch_name}`}
                      getOptionValue={(option) => `${option.id}`}
                      placeholder="by branch"
                      onChange={(data) => changeFilter(filter, data, 'branch')}
                  />
                </Col>
                <Col>
                  <button
                      className="search-input-field__btn search-btn-branch"
                      onClick={searchConversation}
                  ></button>
                </Col>
              </Row>
            </div>

            <h5 className="open-conversations-header">MY CONVERSATIONS</h5>
            <div className="my-conversation-block">
              {state.conversations.filtered.open.my.map((conversation) => (
                <div className="conversation__card-multi-close">
                  {multiClose && (
                    <div className="conversation__check-close">
                      <input
                        type="checkbox"
                        name="close"
                        onChange={() => addConversationToMultiClose(conversation.id)}
                      />
                    </div>
                  )}
                  <Link
                    key={conversation.id}
                    onClick={() => updateUnreadMessage(conversation.id)}
                    to={`/conversations/open/${conversation.id}`}
                  >
                    <ConversationCard conversation={conversation} />
                  </Link>
                </div>
              ))}
            </div>

            <br></br>
            <h5 className="open-conversations-header">OTHER CONVERSATIONS</h5>
            <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMore}
                height={700}
                loader={<h4></h4>}
                initialScrollY={scrollPosition}
                onScroll={handleScroll}
            >
              {state.conversations.filtered.open.other.map((conversation, key) => (
                  <div key={key} className="conversation__card-multi-close">
                    {multiClose && (
                        <div className="conversation__check-close">
                          <input
                              type="checkbox"
                              name="close"
                              onChange={() => addConversationToMultiClose(conversation.id)}
                          />
                        </div>
                    )}
                    <Link
                        key={conversation.id}
                        onClick={() => updateUnreadMessage(conversation.id)}
                        to={`/conversations/open/${conversation.id}`}
                    >
                      {typeof conversation.latest_message != "undefined" ? (
                          <ConversationCard conversation={conversation} />
                      ) : null}
                    </Link>
                  </div>
              ))}
            </InfiniteScroll>
          </Col>
          <Col>
            <div>
              <Route
                path="/conversations/open/:id"
                render={() => <ConversationDetails />}
              />
            </div>
          </Col>

        </Row>

      ) : (
        ""
      )}
    </Container>
  );
};

export default OpenConversations;
